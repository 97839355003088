import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';

// import { parseTime } from '../../../../utils/convert.js';

Vue.use(Vant);

export default {
    name: "GnrhPhysicalList",
    data() {
        return {
            isEmpty: true,

            token: '',
            patientUuid: '',

            list: [],
            loading: false,
            finished: false,
            page: 1,
            pageSize: 10
        }
    },
    mounted() {
        this.token = this.$route.query.token;
        this.patientUuid = this.$route.query.patientUuid;
        this.loadGnrhPhysical(false);
    },
    methods: {
        gotoAdd() {
            this.$router.push({
                path: "/gnrhPhysical",
                query: {
                    patientUuid: this.patientUuid
                }
            });
        },
        gotoDetail(item) {
            console.log("item", item);
            this.$router.push({
                path: "/gnrhPhysicalDetail",
                query: {
                    uuid: item.uuid
                }
            });
        },
        onLoad() {
            this.page = this.page + 1;
            this.loadGnrhPhysical(true);
        },
        loadGnrhPhysical(isGetMore) {
            let that = this;
            let param = {
                page: that.page,
                size: that.pageSize,
                userUuid: that.patientUuid
            }

            if(!isGetMore) {
                that.list.splice(0, that.list.length);
            }

            console.log("param", param);

            that.$axios({
                method: "post",
                url: "/api/api/patientGnrhPhysicalRecord/page",
                data: param
            }).then(res => {
                console.log("res", res);
                if(res.data.code === 200) {
                    if(res.data.data !== null && res.data.data.length > 0) {
                        that.isEmpty = false;
                        that.loading = false;

                        let arr = [];
                        res.data.data.forEach(r => {
                            // r.createTime = parseTime(r.createTime);
                            arr.push(r);
                        })
                        that.list = that.list.concat(arr);

                        console.log("list", that.list);
                    } else {
                        that.finished = true;
                    }
                }
            });
        }
    }
}
